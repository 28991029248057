// Use this context to decide whether to allow user to send a message or not
// We also use this context to alert Messages component when the Flask API
// has warmed up
import { createContext, useContext, useReducer } from 'react';

const LoadingContext = createContext();

// Initial loading state
const initialState = false;

function reducer(state, action) {
  switch (action.type) {
    case 'UPDATE_LOADING':
      return action.payload.apiLoading;
    default:
      return state;
  }
}

function LoadingProvider({ children }) {
  const [apiLoading, dispatch] = useReducer(reducer, initialState);

  function updateLoading(apiLoading) {
    dispatch({ type: 'UPDATE_LOADING', payload: { apiLoading } });
  }

  return (
    <LoadingContext.Provider value={{ apiLoading, updateLoading }}>
      {children}
    </LoadingContext.Provider>
  );
}

function useLoading() {
  const context = useContext(LoadingContext);
  if (context === undefined) {
    throw new Error('useLoading must be used within a LoadingProvider');
  }
  return context;
}

export { LoadingContext, LoadingProvider, useLoading };
