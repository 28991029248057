import React from 'react';
import '../../styles/PageContent.css';

export default function PageContent({ title, children }) {
  return (
    <section className='section'>
      <h1 className='section__title'>{title}</h1>
      <div className='section__content'>{children}</div>
    </section>
  );
}
