// Use this context to add messages to the chat
// We can then use this context anywhere we like to access existing messages
import { createContext, useContext, useReducer } from 'react';
import randomGreeting from '../api/greetings-api';

import {
  createNewMessage as c,
  createDummyMessages,
} from '../scripts/create-messages';

const MessagesContext = createContext();

// The first greeting is random
const preloadedMessages = [
  {
    id: 1,
    alias: 'author',
    content: randomGreeting,
    loading: false,
  },
];

function reducer(state, action) {
  switch (action.type) {
    case 'ADD_MESSAGE':
      return [...state, action.payload];
    default:
      return state;
  }
}

function MessagesProvider({ children }) {
  // const [newMessage, setNewMessage] = useState({});
  const [messages, dispatch] = useReducer(reducer, preloadedMessages);

  function createNewMessage(message) {
    dispatch({ type: 'ADD_MESSAGE', payload: c(message) });
  }

  return (
    <MessagesContext.Provider
      value={{ messages, createNewMessage, createDummyMessages }}
    >
      {children}
    </MessagesContext.Provider>
  );
}

function useMessages() {
  const context = useContext(MessagesContext);
  if (context === undefined) {
    throw new Error('useMessages must be used within a MessagesProvider');
  }
  return context;
}

export { MessagesContext, MessagesProvider, useMessages };
