// Use this context to detect the direction of the user scroll
// We use that information to then decide whether to display the arrow
// or not.
import { createContext, useContext, useState, useEffect } from 'react';

const ScrollContext = createContext();

function ScrollProvider({ children }) {
  const [isAtBottom, setIsAtBottom] = useState(null);
  const [lastScrollTop, setLastScrollTop] = useState(0);

  // Hook for detecting scroll direction
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;

      // If the current scroll position is less than the last one recorded,
      // then the user is scrolling up.
      let isBottom = null;
      if (currentScrollTop > lastScrollTop) {
        isBottom =
          window.innerHeight + currentScrollTop !==
          document.documentElement.offsetHeight;
      }

      setIsAtBottom(() => isBottom !== null && isBottom);

      // Update the last scroll top position
      setLastScrollTop(() => currentScrollTop);
    };

    window.addEventListener('scroll', handleScroll);

    // Clean up after component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollTop]); // Include lastScrollTop in the dependency array

  return (
    <ScrollContext.Provider
      value={{ isAtBottom, setIsAtBottom, lastScrollTop, setLastScrollTop }}
    >
      {children}
    </ScrollContext.Provider>
  );
}

function useScroll() {
  const context = useContext(ScrollContext);
  if (context === undefined) {
    throw new Error('useScroll must be used within a ScrollProvider');
  }
  return context;
}

export { ScrollContext, ScrollProvider, useScroll };
