import React from 'react';
import PageContent from './PageContent';

export default function PageNotFound() {
  return (
    <PageContent title='Page Not Found'>
      <p className='section__text center'>
        This page does not exist. Please use the navigation bar to go to a
        different page.
      </p>
    </PageContent>
  );
}
