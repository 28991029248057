import React, { useState, useEffect } from 'react';
import { useMessages } from '../../../contexts/messagesContext';
import { useLoading } from '../../../contexts/loadingContext';

export default function SendMessage() {
  const [message, setMessage] = useState('');

  const { createNewMessage } = useMessages();
  const { apiLoading } = useLoading();

  useEffect(() => {
    if (!apiLoading) {
      // Focus on the input field
      document.querySelector('.chat__input-field').focus();
    }
  }, [apiLoading]);

  function handleSubmit(e) {
    e.preventDefault();
    if (message === '') return null;

    createNewMessage({ alias: 'user', content: message });
    setMessage('');
  }

  function handleChange(e) {
    setMessage(e.target.value);
  }

  return (
    <div className='chat__input'>
      <form onSubmit={handleSubmit}>
        <input
          type='text'
          value={message}
          className={`chat__input-field ${apiLoading ? 'disabled' : 'active'}`}
          placeholder='Say something...'
          onChange={handleChange}
          disabled={apiLoading}
        />
        <button
          className={`chat__input-button ${apiLoading ? 'disabled' : ''}`}
        >
          Chat
        </button>
      </form>
    </div>
  );
}
