import React from 'react';
import PageContent from './PageContent';

import '../../styles/About.css';

export default function About() {
  return (
    <PageContent title='About Me'>
      <p className='section__image'>
        <img src='assets/author-200x200.webp' alt='Jawad Shuaib' />
      </p>
      <p className='section__text'>
        I love software development. But just about everything else also
        interests me. I never let myself go to sleep without having learned
        something new each day.
      </p>
      <p className='section__text'>
        I have tons of experience leveraging software to help businesses grow.
        My professional experience can be found on{' '}
        <a href='https://www.linkedin.com/in/jawadshuaib'>LinkedIn</a>. You can
        also view my side projects on{' '}
        <a href='https://flat-thrill-2e4.notion.site/Side-Projects-f392d5a68903479084194f33b79d8ebd'>
          Notion
        </a>{' '}
        and <a href='https://github.com/jawadshuaib'>Github</a>.
      </p>
      <p className='section__text'>
        I can be reached at <strong>jawad.php@gmail.com</strong>
      </p>
    </PageContent>
  );
}
