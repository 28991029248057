import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useMessages } from '../../../contexts/messagesContext';
import { useLoading } from '../../../contexts/loadingContext';

function get_alias_properties(alias) {
  switch (alias) {
    case 'author':
      return {
        name: 'Jawad Shuaib',
        image: 'assets/author.webp',
        link: '/about',
      };
    default:
      // Placeholder image
      return { name: 'You', image: 'assets/user.webp', link: null };
  }
}

export default function Message({ id, alias, content, loading }) {
  // State
  const [message, setMessage] = useState({ content: '', current: 0 });
  const [isLastMessage, setIsLastMessage] = useState(false);
  // Context
  const { messages } = useMessages();
  const { apiLoading } = useLoading();

  const { name, image, link } = get_alias_properties(alias);

  // Hook - animate the text
  useEffect(() => {
    // Don't animate user's messages
    if (alias === 'user') return;

    // Only animate the last message
    if (messages[messages.length - 1].id !== id) {
      // This is not the last message, so we immediately
      // show to full content without animation
      setMessage({ content, current: content.length });
      setIsLastMessage(false);
      return;
    }

    // We only show loading spinner if this is the last message
    setIsLastMessage(true);

    // Animates the text
    var contentArray = content.split('');

    const timerId = setInterval(() => {
      setMessage((prevMessage) => {
        const newCurrent = prevMessage.current + 1;
        if (newCurrent >= contentArray.length) {
          clearInterval(timerId); // Stop the animation when reaching the end
        }
        return {
          content: prevMessage.content + contentArray[prevMessage.current],
          current: newCurrent,
        };
      });
    }, 5);

    // Clean up function to clear interval
    return () => clearInterval(timerId);
  }, [content, alias, id, messages]);

  // If the component is in a loading state, display a loading spinner.
  // Otherwise, if the alias is 'author', the API is still loading, and it's the last message, display a loading spinner.
  // If the alias is 'author', the API is still loading, but it's not the last message, display the message content (Note: This might be an inconsistency in the logic since it's displaying the content even if the API is still loading).
  // If the alias is 'author' and the API is not loading, display the message content.
  // If the alias is 'user', display the content.
  // For all other cases, don't display anything.

  return (
    <div className={`chat__message chat__message--${alias}`}>
      <div className='chat__message-author'>
        {link !== null ? (
          <Link to={link}>
            <img className='chat__message-avatar' src={image} alt={name} />
          </Link>
        ) : (
          <img className='chat__message-avatar' src={image} alt={name} />
        )}
      </div>
      <div className='chat__message-content'>
        {loading ? (
          <img src='assets/loading.svg' alt='loading spinner' />
        ) : alias === 'author' && apiLoading && isLastMessage ? (
          <img src='assets/loading.svg' alt='loading spinner' />
        ) : alias === 'author' && apiLoading && !isLastMessage ? (
          <span dangerouslySetInnerHTML={{ __html: message.content }} />
        ) : alias === 'author' && !apiLoading ? (
          <span dangerouslySetInnerHTML={{ __html: message.content }} />
        ) : alias === 'user' ? (
          <span>{content}</span>
        ) : null}
      </div>
    </div>
  );
}
