import React, { useState } from 'react';
import '../../../styles/Arrow.css';
import scrollDown from '../../../scripts/scroll-down';

export default function Arrow() {
  const [display, setDisplay] = useState(true);

  function handleClick() {
    scrollDown();
    setDisplay(false);
  }

  return (
    display && (
      <span className='arrow' onClick={handleClick}>
        <img src='assets/arrow-down.png' alt='Down Arrow' />
      </span>
    )
  );
}
