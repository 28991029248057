import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React from 'react';

import { envVariables } from '../scripts/env-variables';
import '../styles/App.css';
import Nav from './Nav';
import HowItWorks from './pages/HowItWorks';
import Chat from './pages/Chat';
import About from './pages/About';
import PageNotFound from './pages/PageNotFound';
import DeveloperMode from './pages/helper-components/DeveloperMode';
import { MessagesProvider } from '../contexts/messagesContext';
import { LoadingProvider } from '../contexts/loadingContext';

function App() {
  return (
    <Router>
      {envVariables.isDeveloperMode && <DeveloperMode />}
      <Nav />
      <LoadingProvider>
        <MessagesProvider>
          <Routes>
            <Route path='/' element={<Chat />} />
            <Route path='/how-it-works' element={<HowItWorks />} />
            <Route path='/about' element={<About />} />
            <Route path='*' element={<PageNotFound />} />
          </Routes>
        </MessagesProvider>
      </LoadingProvider>
      {/* <Footer /> */}
    </Router>
  );
}

export default App;
