import React, { useState, useEffect } from 'react';
import { useMessages } from '../../../contexts/messagesContext';
import { useScroll } from '../../../contexts/scrollContext';
import { useLoading } from '../../../contexts/loadingContext';

import scrollDown from '../../../scripts/scroll-down';
import { urlify } from '../../../scripts/common-utils';
import getReply from '../../../api/fetch-get-reply-api';

import Message from './Message';
import Arrow from '../helper-components/Arrow';

export default function Messages() {
  // State
  const [loading, setLoading] = useState(false);
  // Contexts
  const { messages, createNewMessage } = useMessages();
  const { isAtBottom } = useScroll();
  const { updateLoading } = useLoading();

  // Hook - fetch API when the last message is from the user
  useEffect(() => {
    // Stop the API from executing if the last message is from the author
    // We only want to execute the API when the last message is from the user
    const lastMessage = messages.length > 0 && messages[messages.length - 1];
    const message = lastMessage.alias === 'user' ? lastMessage : null;

    if (!message) return;

    setLoading(true);

    getReply(message).then((response) => {
      let error = 'hmmmm....something went wrong.';
      if (response.hasOwnProperty('error')) {
        error =
          'Sorry, there was an error returning the answer. The API might have reached its limit.';
      }

      setLoading(false);

      // Push response from the API to the list of messages
      createNewMessage({
        alias: 'author',
        content: urlify(response.reply) || error,
      });
    });
  }, [messages, createNewMessage]);

  // // Create dummy messages for testing purposes
  // useEffect(() => {
  //   createDummyMessages(10).forEach((message) => messages.push(message));
  // }, [messages]);

  // Hook - scroll to the bottom of the chat everytime messages change
  useEffect(() => {
    // Don't scroll down if this is the first message
    // We want the user to be able to see the greeting
    if (messages.length === 1) return;

    scrollDown();
  }, [messages]);

  useEffect(() => {
    updateLoading(loading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <div className='chat__messages'>
      {/* Show downward arrow when user scrolls up */}
      {isAtBottom === false && <Arrow direction='down' />}

      {/* Show all the messages */}
      {messages.map((message) => (
        <Message key={message.id} {...message} />
      ))}

      {/* Show loading ... for the AI until it receives back API response */}
      {loading && (
        <Message
          key={`loading-${Date.now()}`}
          alias='author'
          content=''
          loading={true}
        />
      )}
    </div>
  );
}
