const greetings = [
  'Hi, I am an AI alias for Jawad. I have been trained on a large language model to answer questions about myself...especially those pertaining to my career. Ask me anything!',
  "Hello! I'm a synthetic version of Jawad, built using large language models to share my professional journey. Go ahead, ask me anything!",
  "Welcome! I represent Jawad as an AI, designed to answer inquiries about my career. I'm here for all your questions!",
  "Greetings! I'm an AI avatar for Jawad, armed with insights about my career. Curious? Just ask!",
  "Hi there! As Jawad's AI proxy, I'm here to answer all your career-related queries. What do you want to know?",
  "Hello! I'm Jawad's AI alter ego, equipped with knowledge about my career path. Ready for your questions!",
  "Welcome! As Jawad's AI companion, I'm programmed to enlighten you about my career journey. Fire away with your questions!",
  "Hey there! I'm an AI version of Jawad, ready to dive into career-related discussions. What can I assist you with?",
  "Hi! You're chatting with an AI designed after Jawad. I'm here to discuss my career. So, what's your question?",
  "Greetings! Jawad's AI incarnation here, at your service to discuss my professional life. Feel free to ask me anything!",
  "Hello! I'm Jawad's AI counterpart, primed to address your queries about my career. What would you like to know?",
  "Hi! I'm Jawad's AI representative, trained to answer questions about my work life. What's on your mind?",
  "Greetings! I'm an AI reflection of Jawad, eager to chat about my career. What do you wish to know?",
  "Hey! Jawad's AI persona here, ready to discuss my career journey. What are you curious about?",
  "Hello there! I'm an AI, modeled after Jawad, designed to share my career path. What query can I answer for you?",
  "Welcome! As Jawad's AI version, I'm all set to entertain your career-oriented questions. Let's get started!",
  "Hi! Jawad's AI rendition here, trained to enlighten you about my career. What's your first question?",
  "Greetings! I'm an AI adaptation of Jawad, geared up to answer all your career-focused inquiries. What would you like to know?",
  "Hello! As an AI personification of Jawad, I'm ready to tackle questions about my career. Let's begin, shall we?",
  "Hey there! I'm the AI interpretation of Jawad, at your service to chat about my career. What can I assist you with?",
  "Hi! As an AI profile of Jawad, I'm here to divulge details about my career. Shoot your questions!",
];

const randomGreeting = greetings[Math.floor(Math.random() * greetings.length)];
export default randomGreeting;
