const urlify = (text) => {
  var urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, function (url) {
    return (
      '<a href="' +
      url +
      '" target="_blank" class="chat__message--link">' +
      url +
      '</a>'
    );
  });
};

export { urlify };
