import { envVariables } from '../scripts/env-variables';

const baseURL = `${
  envVariables.isDeveloperMode
    ? envVariables.isDeveloperEndPoint
    : envVariables.corsProxy + envVariables.isProductionEndPoint
}`;

/**
 * API to request get_answer
 * @params: ?query=What is your name?
 * @result: {"response":"Jawad Shuaib."}
 */
const getReply = async (payload) => {
  if (payload.alias === 'author') return;

  const query = payload.content;
  const url = `${baseURL}/get_answer?query=${query}`;

  try {
    const result = await fetch(url);
    const response = await result.json();

    if (response.response === undefined) {
      throw new Error('Something went wrong. Answer not found.');
    }

    return {
      reply: response.response,
    };
  } catch (error) {
    return { error };
  }
};

export default getReply;
