import React, { useState, useEffect } from 'react';

import '../../styles/Chat.css';

import Messages from './chat-components/Messages';
import SendMessage from './chat-components/SendMessage';
import { ScrollProvider } from '../../contexts/scrollContext';
import { useLoading } from '../../contexts/loadingContext';
import prewarmFlaskAPI from '../../api/prewarm-flask-api';
import Error from './helper-components/Error';

export default function Chat() {
  const [error, setError] = useState({ state: false, message: '' });
  const { updateLoading } = useLoading();

  useEffect(() => {
    // Prewarm the Flask API
    // So subsequent requests to OpenAI are faster
    updateLoading(true);
    prewarmFlaskAPI().then((response) => {
      if (response.success === false) {
        setError({ state: true, message: response.message });
        return;
      }

      updateLoading(false);
      setError({
        state: false,
        message: '',
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setError]);

  return (
    <div className='chat'>
      {error.state === true && <Error>Error: {error.message}</Error>}
      <ScrollProvider>
        <Messages />
      </ScrollProvider>
      <SendMessage />
    </div>
  );
}
