import React from 'react';
import '../styles/Nav.css';

import { Link } from 'react-router-dom';

export default function Nav() {
  return (
    <header className='header'>
      <nav className='nav'>
        <Link to='/' className='nav__link-logo'>
          <span className='logo'>Jawad Shuaib</span>
        </Link>
        <ul className='nav__list'>
          <li className='nav__item'>
            <Link to='/how-it-works' className='nav__link'>
              How it Works
            </Link>
          </li>
          <li className='nav__item'>
            <Link to='/about' className='nav__link'>
              About Me
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
}
