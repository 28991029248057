// This function will be used to pre-warm the Flask API
// We will run it as soon as the React App mounts
import { envVariables } from '../scripts/env-variables';

const baseURL = `${
  envVariables.isDeveloperMode
    ? envVariables.isDeveloperEndPoint
    : envVariables.corsProxy + envVariables.isProductionEndPoint
}`;

export default function prewarmFlaskAPI() {
  const apiUrl = `${baseURL}/prewarm`;

  return fetch(apiUrl)
    .then((response) => {
      if (!response.ok) {
        throw new Error(
          'API Server is not responding due to overloaded. Please come back later.'
        );
      }

      return {
        success: true,
        status: response.status,
        statusText: response.statusText,
      };
    })
    .catch((error) => {
      return {
        success: false,
        message: error.message,
      };
    });
}
