import React from 'react';
import '../../../styles/Error.css';

export default function Error({ children }) {
  return (
    <section>
      <div className='error'>{children}</div>
    </section>
  );
}
