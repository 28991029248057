import React from 'react';
import '../../../styles/DeveloperMode.css';

export default function DeveloperMode() {
  return (
    <section>
      <div className='developer-mode'>Developer Mode</div>
    </section>
  );
}
