import React from 'react';
import PageContent from './PageContent';
import '../../styles/HowItWorks.css';

export default function HowItWorks() {
  return (
    <PageContent title='How it Works'>
      <p className='section__text'>
        This chatbot uses a large language model (LLM) to generate responses to
        your messages. When you enter a message, the chatbot requests the
        back-end API I built.
      </p>
      <p className='section__text'>
        The API queries a vectorized document database that is trained on
        finding the most relevant answers related to my professional career.
        That vectorized result is then fed to the LLM, along with a prompt to
        contextualize response style, to generate a response to your message. A
        history of your messages and the model's responses are fed back into the
        prompt to generate the next response while maintaining the overall
        context.
      </p>
      <p className='section__text architecture'>
        <img src='assets/architecture.png' alt='Application architecture' />
      </p>
      <p className='section__text'>
        I used LangChain framework to vectorize the dataset and query the LLM
        model. Currently, I am using gpt-3.5-turbo by OpenAI. This is the same
        generative AI model that powers ChatGPT. While ChatGPT was trained to
        answer general questions, this chatbot has been trained to answer
        questions related to me.
      </p>

      <p className='section__text'>
        List of tools used to build this application:
      </p>

      <strong>Front-end:</strong>
      <ul>
        <li>JavaScript</li>
        <li>React</li>
      </ul>
      <strong>Back-end:</strong>
      <ul>
        <li>Python</li>
        <li>Flask</li>
      </ul>
      <strong>Machine learning:</strong>
      <ul>
        <li>LangChain</li>
        <li>OpenAI</li>
        <li>FAISS</li>
      </ul>
      <strong>Hosting:</strong>
      <ul>
        <li>Netlify for React app</li>
        <li>Azure for Flask app</li>
        <li>Github for continuous deployment</li>
      </ul>
    </PageContent>
  );
}
